import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function BasicTable({ data }) {
  return (
    <TableContainer sx={{ minHeight: 300, maxHeight: 440 }} component={Paper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>序号</TableCell>
            <TableCell>编号</TableCell>
            <TableCell>部门</TableCell>
            <TableCell>姓名</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={row.index}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{row.number}</TableCell>
              <TableCell>{row.department}</TableCell>
              <TableCell>{row.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
