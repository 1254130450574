const generateCsv = (filename, users) => {
  let CsvString = [
    ['序号', '编号', '部门', '姓名'],
    ...users
      .sort((a, b) => a.number - b.number)
      .map(({ number, department, name }, index) => [
        Number(index) + 1,
        number,
        department,
        name,
      ]),
  ]
    .map((item) => item.join(','))
    .join('\r\n');
  CsvString =
    'data:application/vnd.ms-excel;charset=utf-8,\uFEFF' +
    encodeURIComponent(CsvString);
  const link = document.createElement('a');
  link.href = CsvString;
  link.download = `${filename}`;
  link.click();
  link.remove();
};
export default generateCsv;
