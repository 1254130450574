import { useState, useEffect } from 'react';
import { useQrcode } from './hooks/useQrcode';
import { useHistory } from './hooks/useHistory';
import Table from './components/Table';
import generateCsv from './util/generateExcel';

import './App.css';

function App() {
  const { startScan } = useQrcode();
  const { saveOne } = useHistory();
  const [codes, setCodes] = useState([]);
  const [canExport, setCanExport] = useState(false);

  useEffect(() => {
    document.querySelector('body').addEventListener(
      'touchstart',
      function (e) {
        document.querySelectorAll('audio').forEach((ele) => {
          ele.load();
        });
      },
      false
    );
  }, []);

  const successBeep = () => {
    const successAudioEl = document.getElementById('successAudio');
    successAudioEl.play();
  };

  const failedBeep = () => {
    const failedAudioEl = document.getElementById('failedAudio');
    failedAudioEl.play();
  };

  useEffect(() => {
    setCanExport(codes.length > 0);
  }, [codes]);

  const onClick = () => {
    startScan(
      (data) => {
        const alreadyScanned = codes.find(
          (code) => code.number === data.number
        );
        if (alreadyScanned) {
          failedBeep();
        } else {
          successBeep();
          setCodes([...codes, data]);
        }
      },
      (err) => {
        console.log('err', err);
      }
    );
  };

  const onExport = () => {
    const hour = new Date().getHours();
    const min = new Date().getMinutes();
    const filename = `幸运儿名单 ${hour}：${min}`;
    generateCsv(filename, codes);
    saveOne(filename, codes);
    setCanExport(false);
  };

  return (
    <div className='App'>
      <div id='reader'></div>
      <audio
        id='successAudio'
        src='https://scann.osborn.wang/mp3/success.mp3'
      ></audio>
      <audio
        id='failedAudio'
        src='https://scann.osborn.wang/mp3/failed.mp3'
      ></audio>
      <div className='content'>
        <div className='table'>
          <Table data={codes}></Table>
        </div>

        <button className='scann-button' onClick={onClick}>
          Scan
        </button>
        <div className='buttons'>
          <button className='button' onClick={onExport} disabled={!canExport}>
            Export & Save
          </button>
        </div>

        <a href='/history' target='_self' rel='noopener noreferrer'>
          See History
        </a>
      </div>
    </div>
  );
}

export default App;
