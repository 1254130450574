import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider, Link } from 'react-router-dom';
import App from './App';
import History from './History';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
  },
  {
    path: '/history',
    element: <History />,
  },
]);

createRoot(document.getElementById('root')).render(
  <RouterProvider router={router} />
);
