import { useState, useEffect } from 'react';
import { Html5Qrcode } from 'html5-qrcode';
import { USER_GROUP } from '../baseData';

export const useQrcode = () => {
  const config = { fps: 10, qrbox: { width: 250, height: 250 } };
  const [scanner, setScanner] = useState(null);
  useEffect(() => {
    initScanner();
  }, []);

  const initScanner = () => {
    const html5QrCode = new Html5Qrcode('reader');
    setScanner(html5QrCode);
  };

  const failedBeep = () => {
    const failedAudioEl = document.getElementById('failedAudio');
    failedAudioEl.play();
  };

  const startScan = (callback, errorCallback) => {
    if (!scanner) {
      initScanner();
    }
    scanner
      .start(
        { facingMode: 'environment' },
        config,
        (data) => {
          if (data) {
            const matchedPeople = USER_GROUP.find(
              (user) => Number(user.number) === Number(data)
            );
            scanner.stop();

            if (matchedPeople) {
              callback(matchedPeople);
            } else {
              failedBeep();
              errorCallback();
            }
          }
        },
        errorCallback
      )
      .catch((err) => err);
  };

  return {
    scanner,
    initScanner,
    startScan,
  };
};
