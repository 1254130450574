import { useState, useEffect } from 'react';
import dayjs from 'dayjs';

const store_key = 'store_key';

export const useHistory = () => {
  const [history, setHistory] = useState([]);

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = () => {
    const dataStr = localStorage.getItem(store_key);
    if (dataStr) {
      try {
        const data = JSON.parse(dataStr);
        setHistory(data);
      } catch (error) {}
    }
  };

  const saveOne = (name, data) => {
    localStorage.setItem(
      store_key,
      JSON.stringify([
        ...history,
        {
          name,
          data,
          date: dayjs().format('HH:mm:ss'),
        },
      ])
    );
  };

  const removeOne = (name) => {
    localStorage.setItem(
      store_key,
      JSON.stringify(history.filter((item) => item.name !== name))
    );

    setTimeout(() => {
      getAllData();
    }, 200);
  };

  const removeAll = () => {
    localStorage.setItem(store_key, JSON.stringify([]));
    setHistory([]);
  };

  return {
    history,
    saveOne,
    removeOne,
    removeAll,
  };
};
