import { useState } from 'react';
import { useHistory } from './hooks/useHistory';
import generateCsv from './util/generateExcel';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import './App.css';

function App() {
  const { history, removeAll, removeOne } = useHistory();
  const [toRemoveName, setToRemoveName] = useState('');
  const [clearAll, setClearAll] = useState(false);

  const onDownload = ({ name, data }) => {
    generateCsv(name, data);
  };

  const handleClose = ({ name, data }) => {
    setToRemoveName('');
  };

  const onDelete = (row) => {
    setToRemoveName(row.name);
  };

  const onRemove = () => {
    removeOne(toRemoveName);
    setToRemoveName('');
  };

  const closeAll = () => {
    setClearAll(false);
  };

  const onConfirm = () => {
    setClearAll(true);
  };

  const onClear = () => {
    removeAll();
    setClearAll(false);
  };

  return (
    <div className='App'>
      <div className='table'>
        <TableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>time</TableCell>
                <TableCell>名称</TableCell>
                <TableCell>操作</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.date}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>
                    <Stack spacing={2}>
                      <Button
                        size='small'
                        variant='contained'
                        onClick={() => {
                          onDownload(row);
                        }}
                      >
                        Download
                      </Button>

                      <Button
                        size='small'
                        variant='outlined'
                        onClick={() => {
                          onDelete(row);
                        }}
                      >
                        Delete
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className='footer'>
        <button
          className='clear-all'
          onClick={onConfirm}
          disabled={history.length === 0}
        >
          clear all
        </button>

        <a href='/' target='_self' rel='noopener noreferrer'>
          Go scan
        </a>
      </div>

      <Dialog
        open={!!toRemoveName}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            remove {toRemoveName}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={onRemove}>Yes</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={clearAll}
        onClose={closeAll}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Clear all?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeAll}>No</Button>
          <Button onClick={onClear}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default App;
