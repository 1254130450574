export const USER_GROUP = Object.freeze([
  { number: '1', department: '总经理', name: '毛建军' },
  { number: '2', department: '人事', name: '王利莉' },
  { number: '3', department: '人事', name: '蒋晴晴' },
  { number: '4', department: '人事', name: '陈春' },
  { number: '5', department: '人事', name: '於俊辉' },
  { number: '6', department: '人事', name: '周贻萍' },
  { number: '7', department: '财务', name: '龙丽雄' },
  { number: '8', department: '财务', name: '王晓军' },
  { number: '9', department: '财务', name: '李丽' },
  { number: '10', department: '财务', name: '蔡怡佳' },
  { number: '11', department: '财务', name: '吴颖' },
  { number: '12', department: '财务', name: '陈娓' },
  { number: '13', department: '财务', name: '王品蝶' },
  { number: '14', department: '财务', name: '王丽明' },
  { number: '15', department: '财务', name: '张蓉' },
  { number: '16', department: '财务', name: '李瑞兰' },
  { number: '17', department: '财务', name: '邹蕾' },
  { number: '18', department: '财务', name: '欧春霞' },
  { number: '19', department: '采购', name: '苏甦' },
  { number: '20', department: '采购', name: '王思思' },
  { number: '21', department: '采购', name: '张翠敏' },
  { number: '22', department: '采购', name: '周军' },
  { number: '23', department: '采购', name: '张元琦' },
  { number: '24', department: '采购', name: '张梦可' },
  { number: '25', department: '采购', name: '邹敏' },
  { number: '26', department: '采购', name: '王晓霞' },
  { number: '27', department: '采购', name: '范怡雯' },
  { number: '28', department: '采购', name: '干玲玲' },
  { number: '29', department: '采购', name: '章佳琪' },
  { number: '30', department: '采购', name: '吴欣宇' },
  { number: '31', department: '采购', name: '李娟' },
  { number: '32', department: '市场', name: '王波' },
  { number: '33', department: '市场', name: '刘威' },
  { number: '34', department: '市场', name: '陈靖' },
  { number: '35', department: '市场', name: '黄晔' },
  { number: '36', department: '市场', name: '李寒骏' },
  { number: '37', department: '市场', name: '刘银' },
  { number: '38', department: '市场', name: '张文超' },
  { number: '39', department: '市场', name: '刘奕驰' },
  { number: '40', department: '生产', name: '陈小逢' },
  { number: '41', department: '生产', name: '张颖' },
  { number: '42', department: '生产', name: '左海洋' },
  { number: '43', department: '生产', name: '王子玄' },
  { number: '44', department: '生产', name: '夏峥嵘' },
  { number: '45', department: '生产', name: '张磊' },
  { number: '46', department: '生产', name: '庄渊彦' },
  { number: '47', department: '生产', name: '隋苏杰' },
  { number: '48', department: '生产', name: '闫宾' },
  { number: '49', department: '生产', name: '张柳' },
  { number: '50', department: '生产', name: '徐子瞻' },
  { number: '51', department: '生产', name: '邹进' },
  { number: '52', department: '生产', name: '赵鹏' },
  { number: '53', department: '生产', name: '陈雨生' },
  { number: '54', department: '生产', name: '华嘉晟' },
  { number: '56', department: 'IT', name: '戴子强' },
  { number: '57', department: 'IT', name: '李成龙' },
  { number: '58', department: 'IT', name: '曹冬冬' },
  { number: '59', department: 'IT', name: 'Norman' },
  { number: '60', department: 'IT', name: '何以明' },
  { number: '61', department: 'IT', name: '高德新' },
  { number: '62', department: 'IT', name: '严林强' },
  { number: '63', department: 'IT', name: '陆晓辰' },
  { number: '64', department: 'IT', name: '许蔚' },
  { number: '65', department: 'IT', name: '庄丽慧' },
  { number: '66', department: 'IT', name: '唐蓉' },
  { number: '67', department: 'IT', name: '颜春亮' },
  { number: '68', department: 'IT', name: '卢振宇' },
  { number: '69', department: 'IT', name: '李红荣' },
  { number: '70', department: 'IT', name: '徐永强' },
  { number: '71', department: 'IT', name: '王文波' },
  { number: '72', department: 'IT', name: '吴继楠' },
  { number: '73', department: 'IT', name: '汪梦愚' },
  { number: '74', department: 'IT', name: '汪成林' },
  { number: '75', department: 'IT', name: '龚俊辉' },
  { number: '76', department: 'IT', name: '索晨升' },
  { number: '77', department: 'IT', name: '周一帆' },
  { number: '78', department: '拖链', name: '王军' },
  { number: '79', department: '拖链', name: '黄峰' },
  { number: '80', department: '拖链', name: '严智明' },
  { number: '81', department: '拖链', name: '李子轩' },
  { number: '82', department: '拖链', name: '杨柳' },
  { number: '83', department: '拖链', name: '丁西宁' },
  { number: '84', department: '拖链', name: '罗文静' },
  { number: '85', department: '拖链', name: '惠冰' },
  { number: '86', department: '拖链', name: '王兆龙' },
  { number: '87', department: '拖链', name: '刘宁' },
  { number: '88', department: '拖链', name: '吴丽萍' },
  { number: '89', department: '拖链', name: '王宏超' },
  { number: '90', department: '拖链', name: '陈娟' },
  { number: '91', department: '拖链', name: '顾英' },
  { number: '92', department: '拖链', name: '陈晓燕' },
  { number: '93', department: '拖链', name: '顾瑶' },
  { number: '94', department: '拖链', name: '倪水晶' },
  { number: '95', department: '拖链', name: '朱小杰' },
  { number: '96', department: '拖链', name: '郭雁航' },
  { number: '97', department: '拖链', name: '华婕' },
  { number: '98', department: '拖链', name: '吴怡晨' },
  { number: '99', department: '拖链', name: '卢蕊' },
  { number: '100', department: '拖链', name: '刘丽丽' },
  { number: '101', department: '拖链', name: '陈曼' },
  { number: '102', department: '拖链', name: '王贺' },
  { number: '103', department: '拖链', name: '王丽' },
  { number: '104', department: '拖链', name: '杨露' },
  { number: '105', department: '拖链', name: '沈周晨' },
  { number: '106', department: '拖链', name: '余玲' },
  { number: '107', department: '拖链', name: '汤伟' },
  { number: '108', department: '拖链', name: '董奇轩' },
  { number: '109', department: '拖链', name: '严沛发' },
  { number: '110', department: '拖链', name: '张皎' },
  { number: '111', department: '拖链', name: '屈振飞' },
  { number: '112', department: '拖链', name: '晁明月' },
  { number: '113', department: '拖链', name: '张亚运' },
  { number: '114', department: '拖链', name: '唐颀' },
  { number: '115', department: '拖链', name: '曾斌武' },
  { number: '116', department: '拖链', name: '单长峰' },
  { number: '117', department: '拖链', name: '李胜林' },
  { number: '118', department: '拖链', name: '卫峰' },
  { number: '119', department: '拖链', name: '鲁亚军' },
  { number: '120', department: '拖链', name: '岳澧' },
  { number: '121', department: '拖链', name: '张子艳' },
  { number: '122', department: '拖链', name: '刘靓' },
  { number: '123', department: '拖链', name: '朱鑫玮' },
  { number: '124', department: '拖链', name: '刘佳' },
  { number: '125', department: '拖链', name: '谈杨斌' },
  { number: '126', department: '拖链', name: '王科' },
  { number: '127', department: '拖链', name: '陈王荣' },
  { number: '128', department: '拖链', name: '陆攀峰' },
  { number: '129', department: '拖链', name: '张文楠' },
  { number: '130', department: '拖链', name: '梅玉东' },
  { number: '131', department: '拖链', name: '钟塬' },
  { number: '132', department: '拖链', name: '杨庆瑞' },
  { number: '133', department: '拖链', name: '王煜辉' },
  { number: '134', department: '拖链', name: '纽瑞靓' },
  { number: '135', department: '拖链', name: '徐怡' },
  { number: '136', department: '拖链', name: '施丹花' },
  { number: '137', department: '拖链', name: '刘海峰' },
  { number: '138', department: '拖链', name: '张彬' },
  { number: '139', department: '拖链', name: '赵思远' },
  { number: '140', department: '拖链', name: '夏海斌' },
  { number: '141', department: '拖链', name: '叶春柳' },
  { number: '142', department: '拖链', name: '王达春' },
  { number: '143', department: '拖链', name: '钟娟娟' },
  { number: '144', department: '拖链', name: '顾栋' },
  { number: '145', department: '拖链', name: '李海波' },
  { number: '147', department: '拖链', name: '任剑云' },
  { number: '148', department: '拖链', name: '夏剑平' },
  { number: '149', department: '拖链', name: '王敏' },
  { number: '150', department: '拖链', name: '时钰萱' },
  { number: '151', department: '拖链', name: '胡冰' },
  { number: '152', department: '拖链', name: '杨方方' },
  { number: '153', department: '拖链', name: '李佳家' },
  { number: '154', department: '拖链', name: '曹凯' },
  { number: '155', department: '拖链', name: '诸方睿' },
  { number: '156', department: '拖链', name: '张爱娟' },
  { number: '157', department: '拖链', name: '殷兴' },
  { number: '158', department: '拖链', name: '范继强' },
  { number: '159', department: '拖链', name: '甄迅' },
  { number: '160', department: '拖链', name: '刘维刚' },
  { number: '161', department: '拖链', name: '张海洋' },
  { number: '162', department: '拖链', name: '刘文文' },
  { number: '163', department: '拖链', name: '诸挺' },
  { number: '164', department: '拖链', name: '卢妙' },
  { number: '165', department: '拖链', name: '王仇展' },
  { number: '166', department: '拖链', name: '李新利' },
  { number: '167', department: '拖链', name: '孔超' },
  { number: '168', department: '拖链', name: '汪超' },
  { number: '169', department: '拖链', name: '樊振义' },
  { number: '170', department: '拖链', name: '蒋艺' },
  { number: '171', department: '拖链', name: '李志德' },
  { number: '172', department: '拖链', name: '臧志运' },
  { number: '173', department: '拖链', name: '丁伟' },
  { number: '174', department: '拖链', name: '钟艳鹏' },
  { number: '175', department: '拖链', name: '章勤' },
  { number: '176', department: '拖链', name: '宛剑' },
  { number: '177', department: '拖链', name: '高雪文' },
  { number: '178', department: '拖链', name: '刘冰' },
  { number: '179', department: '拖链', name: '孟翔鹏' },
  { number: '180', department: '拖链', name: '汪明' },
  { number: '181', department: '拖链', name: '项阳' },
  { number: '182', department: '拖链', name: '宋闯' },
  { number: '183', department: '拖链', name: '邢静' },
  { number: '184', department: '拖链', name: '王俊淇' },
  { number: '185', department: '拖链', name: '陈万祥' },
  { number: '186', department: '拖链', name: '杨泽宇' },
  { number: '187', department: '拖链', name: '曹树宝' },
  { number: '188', department: '拖链', name: '益丽宏' },
  { number: '189', department: '拖链', name: '程智勇' },
  { number: '190', department: '拖链', name: '岳涛' },
  { number: '191', department: '拖链', name: '邓鼎' },
  { number: '192', department: '拖链', name: '蔡明' },
  { number: '193', department: '拖链', name: '张双山' },
  { number: '194', department: '拖链', name: '卫国生' },
  { number: '195', department: '拖链', name: '赵月月' },
  { number: '196', department: '拖链', name: '李新江' },
  { number: '197', department: '拖链', name: '吴丹宁' },
  { number: '198', department: '拖链', name: '谭振流' },
  { number: '199', department: '拖链', name: '陈磊' },
  { number: '200', department: '拖链', name: '庄城燕' },
  { number: '201', department: '拖链', name: '刘倩雯' },
  { number: '202', department: '拖链', name: '杨宇明' },
  { number: '203', department: '拖链', name: '苏京域' },
  { number: '204', department: '拖链', name: '李玉州' },
  { number: '205', department: '拖链', name: '张学诚' },
  { number: '206', department: '拖链', name: '刘人滈' },
  { number: '207', department: '拖链', name: '刘钢' },
  { number: '208', department: '拖链', name: '庞双' },
  { number: '209', department: '拖链', name: '何陆妍' },
  { number: '210', department: '拖链', name: '徐翠萍' },
  { number: '211', department: '拖链', name: '张琳莉' },
  { number: '212', department: '拖链', name: '陆文奇' },
  { number: '213', department: '拖链', name: '陈军' },
  { number: '214', department: '拖链', name: '颜翔' },
  { number: '215', department: '拖链', name: '赵世通' },
  { number: '216', department: '拖链', name: '李朝辉' },
  { number: '217', department: '拖链', name: '刘先胜' },
  { number: '218', department: '拖链', name: '王晓' },
  { number: '219', department: '拖链', name: '刘会' },
  { number: '220', department: '拖链', name: '杨建桥' },
  { number: '221', department: '拖链', name: '陈亮' },
  { number: '222', department: '拖链', name: '刘美松' },
  { number: '223', department: '拖链', name: '胡铭松' },
  { number: '224', department: '拖链', name: '王彦娣' },
  { number: '225', department: '拖链', name: '叶辉' },
  { number: '226', department: '拖链', name: '莫冲' },
  { number: '227', department: '拖链', name: '黄鑫' },
  { number: '228', department: '拖链', name: '林鑫炜' },
  { number: '229', department: '拖链', name: '廖龙平' },
  { number: '230', department: '拖链', name: '李帅' },
  { number: '231', department: '拖链', name: '邵亨南' },
  { number: '232', department: '拖链', name: '黄志勇' },
  { number: '233', department: '拖链', name: '刘静' },
  { number: '234', department: '拖链', name: '刘志辰' },
  { number: '235', department: '拖链', name: '赵振辉' },
  { number: '236', department: '拖链', name: '张凯' },
  { number: '237', department: '拖链', name: '罗太容' },
  { number: '238', department: '拖链', name: '齐秀园' },
  { number: '239', department: '拖链', name: '梁凉' },
  { number: '240', department: '拖链', name: '李三根' },
  { number: '241', department: '拖链', name: '孙倩倩' },
  { number: '242', department: '拖链', name: '孙振宇' },
  { number: '243', department: '拖链', name: '郭永明' },
  { number: '244', department: '拖链', name: '于海波' },
  { number: '245', department: '拖链', name: '吴凯' },
  { number: '246', department: '拖链', name: '孟潇洋' },
  { number: '247', department: '拖链', name: '胡海量' },
  { number: '248', department: '拖链', name: '张石源' },
  { number: '249', department: '拖链', name: '林广阔' },
  { number: '250', department: '拖链', name: '马晓波' },
  { number: '251', department: '拖链', name: '范华龙' },
  { number: '252', department: '拖链', name: '唐闻婧' },
  { number: '253', department: '拖链', name: '张道兴' },
  { number: '254', department: '拖链', name: '薛诚' },
  { number: '255', department: '拖链', name: '王淑波' },
  { number: '256', department: '拖链', name: '冯港' },
  { number: '257', department: '拖链', name: '杨旻' },
  { number: '258', department: '拖链', name: '周臻' },
  { number: '259', department: '拖链', name: '孙薇淼' },
  { number: '260', department: '拖链', name: '江波' },
  { number: '261', department: '拖链', name: '龚明鑫' },
  { number: '262', department: '拖链', name: '程金松' },
  { number: '263', department: '拖链', name: '龚广东' },
  { number: '264', department: '拖链', name: '蔡贇' },
  { number: '265', department: '拖链', name: '陈彬荣' },
  { number: '266', department: '拖链', name: '潘定富' },
  { number: '267', department: '拖链', name: '朱楠' },
  { number: '268', department: '拖链', name: '高荐贤' },
  { number: '269', department: '拖链', name: '陈蕾' },
  { number: '270', department: '拖链', name: '邹凡' },
  { number: '271', department: '拖链', name: '徐劭杰' },
  { number: '272', department: '拖链', name: '丁锐' },
  { number: '273', department: '拖链', name: '陈柯' },
  { number: '274', department: '拖链', name: '李本华' },
  { number: '275', department: '拖链', name: '侯文峰' },
  { number: '276', department: '拖链', name: '高红阳' },
  { number: '277', department: '拖链', name: '张弛' },
  { number: '278', department: '拖链', name: '朱逸' },
  { number: '279', department: '拖链', name: '吴千玮' },
  { number: '280', department: '拖链', name: '李鼎' },
  { number: '282', department: '拖链', name: '王蓉凤' },
  { number: '283', department: '拖链', name: '丁亮' },
  { number: '284', department: '拖链', name: '胡飞飞' },
  { number: '285', department: '拖链', name: '周晓蓓' },
  { number: '286', department: '拖链', name: '李军' },
  { number: '287', department: '拖链', name: '杨文慧' },
  { number: '288', department: '拖链', name: '成龙' },
  { number: '289', department: '拖链', name: '傅涵仪' },
  { number: '290', department: '拖链', name: '郜家鼎' },
  { number: '291', department: '拖链', name: '吴瑜' },
  { number: '292', department: '拖链', name: '承佳妮' },
  { number: '293', department: '拖链', name: '邱伟毅' },
  { number: '294', department: '拖链', name: '刘琪' },
  { number: '295', department: '拖链', name: '李渊' },
  { number: '296', department: '拖链', name: '徐惠强' },
  { number: '297', department: '拖链', name: '羌海峰' },
  { number: '298', department: '拖链', name: '刘波' },
  { number: '299', department: '拖链', name: '陈聪' },
  { number: '300', department: '拖链', name: '闻翠华' },
  { number: '301', department: '拖链', name: '尹亦馨' },
  { number: '302', department: '拖链', name: '欧娜' },
  { number: '303', department: '拖链', name: '陆敏杰' },
  { number: '304', department: '拖链', name: '颜玉雷' },
  { number: '305', department: '拖链', name: '施禕磊' },
  { number: '306', department: '拖链', name: '方运' },
  { number: '307', department: '拖链', name: '武首航' },
  { number: '308', department: '拖链', name: '王子豪' },
  { number: '309', department: '拖链', name: '余飞' },
  { number: '310', department: '拖链', name: '林嘉骏' },
  { number: '311', department: '拖链', name: '李秀秀' },
  { number: '312', department: '拖链', name: '黄艳红' },
  { number: '313', department: '拖链', name: '王晶' },
  { number: '314', department: '拖链', name: '徐晓苓' },
  { number: '315', department: '拖链', name: '孙立君' },
  { number: '316', department: '拖链', name: '胡素娟' },
  { number: '317', department: '轴承', name: '卢飞' },
  { number: '318', department: '轴承', name: '刘维佳' },
  { number: '319', department: '轴承', name: '陈姝' },
  { number: '320', department: '轴承', name: '郑艾林' },
  { number: '321', department: '轴承', name: '虞雯艳' },
  { number: '322', department: '轴承', name: '张思宇' },
  { number: '323', department: '轴承', name: '张悦琦' },
  { number: '324', department: '轴承', name: '江云' },
  { number: '325', department: '轴承', name: '赵璐' },
  { number: '326', department: '轴承', name: '朱艺宣' },
  { number: '327', department: '轴承', name: '王玉娇' },
  { number: '328', department: '轴承', name: '白佳' },
  { number: '329', department: '轴承', name: '曾文苗' },
  { number: '330', department: '轴承', name: '薛建红' },
  { number: '331', department: '轴承', name: '陈杰' },
  { number: '332', department: '轴承', name: '孙青云' },
  { number: '333', department: '轴承', name: '苏嘉' },
  { number: '334', department: '轴承', name: '王志成' },
  { number: '335', department: '轴承', name: '陈博闻' },
  { number: '336', department: '轴承', name: '徐祥' },
  { number: '337', department: '轴承', name: '马艳艳' },
  { number: '338', department: '轴承', name: '谭亚明' },
  { number: '339', department: '轴承', name: '陈钊均' },
  { number: '340', department: '轴承', name: '张哲丰' },
  { number: '341', department: '轴承', name: '谢雪玲' },
  { number: '342', department: '轴承', name: '华春' },
  { number: '343', department: '轴承', name: '蔡敏学' },
  { number: '344', department: '轴承', name: '陈凯' },
  { number: '345', department: '轴承', name: '陈帅' },
  { number: '346', department: '轴承', name: '苏航' },
  { number: '347', department: '轴承', name: '袁施凯' },
  { number: '348', department: '轴承', name: '王梦云' },
  { number: '349', department: '轴承', name: '杨蛟' },
  { number: '350', department: '轴承', name: '程俊杰' },
  { number: '351', department: '轴承', name: '闵顺兰' },
  { number: '352', department: '轴承', name: '陆优超' },
  { number: '353', department: '轴承', name: '尤翼' },
  { number: '354', department: '轴承', name: '刘奇' },
  { number: '355', department: '轴承', name: '杨雨' },
  { number: '356', department: '轴承', name: '蔡荣凡' },
  { number: '357', department: '轴承', name: '范震龙' },
  { number: '358', department: '轴承', name: '李开磊' },
  { number: '359', department: '轴承', name: '杨壹丰' },
  { number: '360', department: '轴承', name: '朱珅东' },
  { number: '361', department: '轴承', name: '冯明炬' },
  { number: '362', department: '轴承', name: '林庆中' },
  { number: '363', department: '轴承', name: '宋美' },
  { number: '364', department: '轴承', name: '刘祥坤' },
  { number: '365', department: '轴承', name: '李宇峰' },
  { number: '366', department: '轴承', name: '瞿益挺' },
  { number: '367', department: '轴承', name: '周志东' },
  { number: '368', department: '轴承', name: '陈永奎' },
  { number: '369', department: '轴承', name: '方君豪' },
  { number: '370', department: '轴承', name: '潘宣' },
  { number: '371', department: '轴承', name: '菅士勤' },
  { number: '372', department: '轴承', name: '冯浩' },
  { number: '373', department: '轴承', name: '李渊' },
  { number: '375', department: '轴承', name: '万鹏' },
  { number: '376', department: '轴承', name: '卢海波' },
  { number: '377', department: '轴承', name: '刘思晗' },
  { number: '378', department: '轴承', name: '蒋陆' },
  { number: '379', department: '轴承', name: '张胜一' },
  { number: '380', department: '轴承', name: '葛国耀' },
  { number: '381', department: '轴承', name: '胡晓康' },
  { number: '382', department: '轴承', name: '郑珽' },
  { number: '383', department: '轴承', name: '马玲玉' },
  { number: '384', department: '轴承', name: '祁琼月' },
  { number: '385', department: '轴承', name: '沈源' },
  { number: '386', department: '轴承', name: '常景玉' },
  { number: '387', department: '轴承', name: '刘海云' },
  { number: '388', department: '轴承', name: '罗威' },
  { number: '389', department: '轴承', name: '刘东伟' },
  { number: '390', department: '轴承', name: '张吉阳' },
  { number: '391', department: '轴承', name: '李强' },
  { number: '392', department: '轴承', name: '原野' },
  { number: '393', department: '轴承', name: '李杰' },
  { number: '394', department: '轴承', name: '高颖' },
  { number: '395', department: '轴承', name: '张宏民' },
  { number: '396', department: '轴承', name: '张光耀' },
  { number: '397', department: '轴承', name: '林浩生' },
  { number: '398', department: '轴承', name: '徐涛' },
  { number: '399', department: '轴承', name: '何贤' },
  { number: '400', department: '轴承', name: '柯贤智' },
  { number: '401', department: '轴承', name: '孙玉怡' },
  { number: '402', department: '轴承', name: '邱榆林' },
  { number: '403', department: '轴承', name: '李亚波' },
  { number: '404', department: '轴承', name: '张珍' },
  { number: '405', department: '轴承', name: '姜显强' },
  { number: '406', department: '轴承', name: '邱坚庭' },
  { number: '407', department: '轴承', name: '万星尧' },
  { number: '408', department: '轴承', name: '彭广能' },
  { number: '409', department: '轴承', name: '蒙颖' },
  { number: '410', department: '轴承', name: '朱蓉' },
  { number: '411', department: '轴承', name: '梁郁辉' },
  { number: '412', department: '轴承', name: '何永东' },
  { number: '413', department: '轴承', name: '黄宇波' },
  { number: '414', department: '轴承', name: '柴文瑞' },
  { number: '415', department: '轴承', name: '尉婷' },
  { number: '416', department: '轴承', name: '陈明杰' },
  { number: '417', department: '轴承', name: '高志慧' },
  { number: '418', department: '轴承', name: '张方伟' },
  { number: '419', department: '轴承', name: '宋怡' },
  { number: '420', department: '轴承', name: '黄鑫锦' },
  { number: '421', department: '轴承', name: '刘涛' },
  { number: '422', department: '轴承', name: '孙世军' },
  { number: '423', department: '轴承', name: '陈亚' },
  { number: '425', department: '轴承', name: '杨幼宏' },
  { number: '426', department: '轴承', name: '金光明' },
  { number: '427', department: '轴承', name: '王蕾' },
  { number: '428', department: '轴承', name: '花雄' },
  { number: '429', department: '轴承', name: '袁成' },
  { number: '430', department: '轴承', name: '季江' },
  { number: '431', department: '轴承', name: '杨颖' },
  { number: '432', department: '轴承', name: '姜昭' },
  { number: '433', department: '轴承', name: '李雅琼' },
  { number: '434', department: '轴承', name: '王世杰' },
  { number: '435', department: '轴承', name: '曹世龙' },
  { number: '436', department: '轴承', name: '都成刚' },
  { number: '437', department: '轴承', name: '刘锴' },
  { number: '438', department: '轴承', name: '靳玄' },
  { number: '439', department: '轴承', name: '马子淇' },
  { number: '440', department: '轴承', name: '苗海波' },
  { number: '441', department: '轴承', name: '张研' },
  { number: '442', department: '轴承', name: '夏宁翔' },
  { number: '443', department: '轴承', name: '熊重光' },
  { number: '444', department: '轴承', name: '梁伟' },
  { number: '445', department: '轴承', name: '王璟' },
  { number: '446', department: '轴承', name: '王瑞' },
  { number: '447', department: '轴承', name: '陈旭东' },
  { number: '448', department: '轴承', name: '张劼昊' },
  { number: '449', department: '轴承', name: '蒲雪梅' },
  { number: '450', department: '轴承', name: '柴培培' },
  { number: '451', department: '轴承', name: '冯紫慧' },
  { number: '452', department: '生产', name: '黄燕' },
  { number: '453', department: '生产', name: '牛德凤' },
  { number: '454', department: '生产', name: '周 垒' },
  { number: '455', department: '生产', name: '张成律' },
  { number: '456', department: '生产', name: '孙鑫鑫' },
  { number: '457', department: '生产', name: '王军' },
  { number: '458', department: '生产', name: '黄通' },
  { number: '459', department: '生产', name: '吕先上' },
  { number: '460', department: '生产', name: '龚言兵' },
  { number: '461', department: '生产', name: '许磊' },
  { number: '462', department: '生产', name: '赵旭明' },
  { number: '463', department: '生产', name: '丁成' },
  { number: '464', department: '生产', name: '邓支辉' },
  { number: '465', department: '生产', name: '赵青山' },
  { number: '466', department: '生产', name: '杨威' },
  { number: '467', department: '生产', name: '杨雷' },
  { number: '468', department: '生产', name: '侯平龙' },
  { number: '469', department: '生产', name: '金学辉' },
  { number: '470', department: '生产', name: '高昆' },
  { number: '471', department: '生产', name: '刘军' },
  { number: '472', department: '生产', name: '黄俊清' },
  { number: '473', department: '生产', name: '邵建' },
  { number: '474', department: '生产', name: '郭兵' },
  { number: '475', department: '生产', name: '张兆平' },
  { number: '476', department: '生产', name: '袁辉' },
  { number: '477', department: '生产', name: '张龙' },
  { number: '478', department: '生产', name: '张平' },
  { number: '479', department: '生产', name: '吴恒俊' },
  { number: '480', department: '生产', name: '许海生' },
  { number: '481', department: '生产', name: '顾成' },
  { number: '482', department: '生产', name: '陈刘' },
  { number: '483', department: '生产', name: '许超' },
  { number: '484', department: '生产', name: '孟振权' },
  { number: '485', department: '生产', name: '任飞耀' },
  { number: '486', department: '生产', name: '王梦想' },
  { number: '487', department: '生产', name: '赵明刚' },
  { number: '488', department: '生产', name: '马兴' },
  { number: '489', department: '生产', name: '南杰红' },
  { number: '490', department: '生产', name: '康苗苗' },
  { number: '491', department: '生产', name: '任显平' },
  { number: '492', department: '生产', name: '赵兵祥' },
  { number: '493', department: '生产', name: '白忠华' },
  { number: '494', department: '生产', name: '祖田' },
  { number: '495', department: '生产', name: '侯平稳' },
  { number: '496', department: '生产', name: '黄尉清' },
  { number: '497', department: '生产', name: '赵陈西' },
  { number: '498', department: '生产', name: '王浩' },
  { number: '499', department: '生产', name: '王秋华' },
  { number: '500', department: '生产', name: '蒋兴' },
  { number: '501', department: '生产', name: '李松闯' },
  { number: '502', department: '生产', name: '袁飞龙' },
  { number: '503', department: '生产', name: '桑海龙' },
  { number: '504', department: '生产', name: '赵计生' },
  { number: '505', department: '生产', name: '柳国苗' },
  { number: '506', department: '生产', name: '赵春元' },
  { number: '507', department: '生产', name: '赵军祥' },
  { number: '508', department: '生产', name: '赵俊岑' },
  { number: '509', department: '生产', name: '黄光成' },
  { number: '510', department: '生产', name: '张正明' },
  { number: '511', department: '生产', name: '张运福' },
  { number: '512', department: '生产', name: '梁亚' },
  { number: '513', department: '生产', name: '王毅' },
  { number: '514', department: '生产', name: '李坤朋' },
  { number: '515', department: '生产', name: '熊志国' },
  { number: '516', department: '生产', name: '沈忠华' },
  { number: '517', department: '生产', name: '张宇浩' },
  { number: '518', department: '生产', name: '李彬彬' },
  { number: '519', department: '生产', name: '卢京京' },
  { number: '520', department: '生产', name: '王晨' },
  { number: '521', department: '生产', name: '袁国松' },
  { number: '522', department: '生产', name: '蒋博' },
  { number: '523', department: '生产', name: '刘井松' },
  { number: '524', department: '生产', name: '尹布磊' },
  { number: '525', department: '生产', name: '徐凯' },
  { number: '526', department: '生产', name: '李锦坤' },
  { number: '527', department: '生产', name: '王建生' },
  { number: '528', department: '生产', name: '赵毛蛋' },
  { number: '529', department: '生产', name: '丁继攀' },
  { number: '530', department: '生产', name: '苟    君' },
  { number: '531', department: '生产', name: '穆桃' },
  { number: '532', department: '生产', name: '郭燕涛' },
  { number: '533', department: '生产', name: '曹玄玄' },
  { number: '534', department: '生产', name: '严志彬' },
  { number: '535', department: '生产', name: '陈正' },
  { number: '536', department: '生产', name: '刘化璐' },
  { number: '537', department: '生产', name: '刘树军' },
  { number: '538', department: '生产', name: '周刚' },
  { number: '539', department: '生产', name: '盛亮' },
  { number: '540', department: '生产', name: '王子行' },
  { number: '541', department: '生产', name: '王芳强' },
  { number: '542', department: '生产', name: '高宝龙' },
  { number: '543', department: '生产', name: '李志' },
  { number: '544', department: '生产', name: '郭安乐' },
  { number: '545', department: '生产', name: '王帅亚' },
  { number: '546', department: '生产', name: '孟涛' },
  { number: '547', department: '生产', name: '熊建国' },
  { number: '548', department: '生产', name: '胡仕正' },
  { number: '549', department: '生产', name: '向忠礼' },
  { number: '550', department: '生产', name: '赵康祥' },
  { number: '551', department: '生产', name: '张吉伟' },
  { number: '552', department: '生产', name: '余雄' },
  { number: '553', department: '生产', name: '孟令喜' },
  { number: '554', department: '生产', name: '杨贻程' },
  { number: '555', department: '生产', name: '李胜' },
  { number: '556', department: '生产', name: '刘永' },
  { number: '557', department: '生产', name: '马宗义' },
  { number: '558', department: '生产', name: '陈德林' },
  { number: '559', department: '生产', name: '沈家坡' },
  { number: '560', department: '生产', name: '霍红伟' },
  { number: '561', department: '生产', name: '华安新' },
  { number: '562', department: '生产', name: '张海龙' },
  { number: '563', department: '生产', name: '周书止' },
  { number: '564', department: '生产', name: '曹小涛' },
  { number: '565', department: '生产', name: '李亚云' },
  { number: '566', department: '生产', name: '王武' },
  { number: '567', department: '生产', name: '邓世双' },
  { number: '568', department: '生产', name: '卢红新' },
  { number: '569', department: '生产', name: '陈满阳' },
  { number: '570', department: '生产', name: '郑柱' },
  { number: '571', department: '生产', name: '张晓光' },
  { number: '572', department: '生产', name: '严炜杰' },
  { number: '573', department: '生产', name: '赵新政' },
  { number: '574', department: '生产', name: '焦航' },
  { number: '575', department: '生产', name: '张玖海' },
  { number: '576', department: '生产', name: '李道建' },
  { number: '577', department: '生产', name: '陈文文' },
  { number: '578', department: '生产', name: '孙坤' },
  { number: '579', department: '生产', name: '张勇来' },
  { number: '580', department: '生产', name: '罗帆' },
  { number: '581', department: '生产', name: '刘博' },
  { number: '582', department: '生产', name: '李亚斌' },
  { number: '583', department: '生产', name: '冉青山' },
  { number: '584', department: '生产', name: '陈国强' },
  { number: '585', department: '生产', name: '王孟云' },
  { number: '586', department: '生产', name: '李芙芸' },
  { number: '587', department: '生产', name: '刘红' },
  { number: '588', department: '生产', name: '熊金香' },
  { number: '589', department: '生产', name: '陈苗苗' },
  { number: '590', department: '生产', name: '严鹏' },
  { number: '591', department: '生产', name: '潘峰' },
  { number: '592', department: '生产', name: '王振西' },
  { number: '593', department: '生产', name: '刘长青' },
  { number: '594', department: '生产', name: '肖森林' },
  { number: '595', department: '生产', name: '潘科' },
  { number: '596', department: '生产', name: '许玉春' },
  { number: '597', department: '生产', name: '刘永跃' },
  { number: '598', department: '生产', name: '刘宾见' },
  { number: '599', department: '生产', name: '鲍浩宇' },
  { number: '600', department: '生产', name: '张树勤' },
  { number: '601', department: '生产', name: '王倩倩' },
  { number: '602', department: '生产', name: '尹洪斌' },
  { number: '603', department: '生产', name: '陈友洪' },
  { number: '604', department: '生产', name: '王明' },
  { number: '605', department: '生产', name: '李彦启' },
  { number: '606', department: '生产', name: '周桂阳' },
  { number: '607', department: '生产', name: '张政' },
  { number: '608', department: '生产', name: '代严红' },
  { number: '609', department: '生产', name: '陈纪飞' },
  { number: '610', department: '生产', name: '宋营华' },
  { number: '611', department: '生产', name: '徐峥' },
  { number: '612', department: '生产', name: '倪明' },
  { number: '613', department: '生产', name: '程亚锋' },
  { number: '614', department: '生产', name: '林波' },
  { number: '615', department: '生产', name: '张尤才' },
  { number: '616', department: '生产', name: '马生建' },
  { number: '617', department: '生产', name: '刘廷' },
  { number: '618', department: '生产', name: '程琛' },
  { number: '619', department: '生产', name: '薛唐河' },
  { number: '620', department: '生产', name: '岳友' },
  { number: '621', department: '生产', name: '何新龙' },
  { number: '622', department: '生产', name: '黄金' },
  { number: '623', department: '生产', name: '王胜峰' },
  { number: '624', department: '生产', name: '潘胜' },
  { number: '625', department: '生产', name: '蔡玉斌' },
  { number: '626', department: '生产', name: '徐明春' },
  { number: '627', department: '生产', name: '朱地远' },
  { number: '628', department: '生产', name: '余继虎' },
  { number: '629', department: '生产', name: '蔡万才' },
  { number: '630', department: '生产', name: '李建国' },
  { number: '631', department: '生产', name: '尹占武' },
  { number: '632', department: '生产', name: '李国兵' },
  { number: '633', department: '生产', name: '李利峰' },
  { number: '634', department: '生产', name: '张好男' },
  { number: '635', department: '生产', name: '邵海涛' },
  { number: '636', department: '生产', name: '冯作刚' },
  { number: '637', department: '生产', name: '贾宗强' },
  { number: '638', department: '生产', name: '卜贺' },
  { number: '639', department: '生产', name: '田佳力' },
  { number: '640', department: '生产', name: '王理想' },
  { number: '641', department: '生产', name: '张记东' },
  { number: '642', department: '生产', name: '梁晓' },
  { number: '643', department: '生产', name: '金佩' },
  { number: '644', department: '生产', name: '尤壮' },
  { number: '645', department: '生产', name: '毛超峰' },
  { number: '646', department: '生产', name: '陈文强' },
  { number: '647', department: '生产', name: '梁双双' },
  { number: '648', department: '生产', name: '牛少辉' },
  { number: '649', department: '生产', name: '王华安' },
  { number: '650', department: '生产', name: '葛亮' },
  { number: '651', department: '生产', name: '周乐乐' },
  { number: '652', department: '生产', name: '田涛' },
  { number: '659', department: 'IT项目', name: '刘文' },
  { number: '660', department: 'IT项目', name: '许楠楠' },
  { number: '661', department: 'IT项目', name: '陈旭' },
  { number: '662', department: 'IT项目', name: '滕飞' },
  { number: '663', department: 'IT项目', name: '赵飞' },
  { number: '664', department: 'IT项目', name: '盛志敏' },
  { number: '665', department: 'IT项目', name: '胡帅' },
  { number: '666', department: 'IT项目', name: '耿国彦' },
  { number: '667', department: 'IT项目', name: '张聪' },
  { number: '668', department: '轴承', name: '韩佩' },
]);
